<template>
  <div>
    <div class="jumbotron-wrapper color">
      <div class="jumbotron container-lg px-4">
        <div class="animation cover-text mt-5">
          <div class="row gx-5">
            <header class="mb-4">
              <p class="fs-1 fw-bolder mb-1">
                非凸算法上线银河证券，助力机构交易业务
              </p>
              <div class="fst-italic mb-2">2022年11月30日</div>
              <a
                class="badge bg-secondary text-decoration-none link-light"
                href="#!"
                >新闻</a
              >
              <a
                class="ms-1 badge bg-secondary text-decoration-none link-light"
                href="#!"
                >银河证券</a
              >
            </header>
          </div>
        </div>
      </div>
    </div>
    <div class="content p-auto">
      <section class="py-5">
        <div class="container-lg px-4">
          <div class="row">
            <div class="col-lg-3">
              <div class="d-flex align-items-center mt-lg-5 mb-4">
                <img
                  class="img-fluid rounded-circle logo_50"
                  src="../../assets/img/logo/logo_50.jpeg"
                  alt="非凸科技"
                />
                <div class="ms-3">
                  <div class="fw-bold">非凸新闻</div>
                  <div class="text-muted">Non-convex News</div>
                </div>
              </div>
            </div>
            <div class="col-lg-6">
              <article class="lh-lg">
                <section class="mb-5">
                  <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/22/01-银河上线.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                  <p>
                    近年来，券商机构业务发展迅速，金融科技助力作用日益凸显。有机构预测，2025年机构投资者占比将达到70%，以及未来每年数智交易量将达到140万亿。此次，非凸智能算法上线银河证券启明iTrade算法中心平台，助力机构提升展业能力、丰富算法策略，完善服务体系等。
                  </p>
                   <p>
                    银河证券正着力发展机构业务，积极投入金融科技技术与创新，不断加强与机构客户的业务合作，而这背后离不开算法服务商的持续发力。非凸科技基于机器学习功底、数据挖掘能力和产品研发实力，全力协助银河证券做好机构客户需求的匹配。
                  </p>
                  <p>
                    在策略快速迭代的进程中，非凸科技充分发挥软硬件结合的优势。硬件方面，使用高性能低延时服务器，通信层耗时大幅降低，执行效率极大提升；系统方面，采用Rust技术架构，结合全新的交易业务数据结构模型和最新迭代算法，保证交易系统的极速和稳定。与此同时，非凸科技自建算力集群，致力于高性能计算的前沿开发和技术应用，助力机构客户获取稳定的超额收益。
                  </p>
                  <p>
                    除此之外，非凸科技持续进行算法服务升级，以期不断提升服务质量和体验，帮助券商获取更多机构业务合作。成立以来，非凸科技始终以务实的精神，深耕业务服务，逐步完成算法体验全流程框架的搭建，培养出一批高素质且专业的产品服务人员。
                  </p>
                  <p>
                    目前，非凸科技已与银河证券、中泰证券、中金财富、东北证券、国联证券、海通证券、申万宏源证券等多家头部券商达成算法合作，算法运行稳定，绩效稳居前列。其他券商也在积极洽谈中，非凸科技将持续赋能券商做好机构业务服务，提升交易执行能力。
                  </p>
                  <p>
                    期待与更多券商合作，共同助力数智交易生态建设！
                  </p>
                 <figure class="mb-4">
                    <img
                      class="img-fluid rounded w-100"
                      src="../../assets/img/news/22/02-银河海报.jpg"
                      alt="非凸科技"
                    />
                  </figure>
                </section>
              </article>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  name: "News22",
};
</script>

<style></style>
